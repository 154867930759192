import React from 'react'
import { Link, useStaticQuery } from 'gatsby'
import Layout from '../components/Layout'
import Img from "gatsby-image"



export default () => {



        const data = useStaticQuery(graphql`
            query {
                faceMask : file(relativePath: { eq: "demos/face_mask.png" }) {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                hapi : file(relativePath: { eq: "demos/hapi.png" }) {
                  childImageSharp {
                      fluid(quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp
                      }
                  }
              }
            }
        `)


        const posts = [
            {
                key: 1,
                title: 'Face mask',
                url: '/demos/face-mask',
                thumbnailImage: 'faceMask',
                date: '25/03/2020',
                description: 'Estima la posición 3D de 486 puntos faciales. Estos puntos se utilizan para colocar una mascarilla sobre la cara del participante.'
            },
            {
              key: 2,
              title: 'Hapi',
              url: '/demos/hapi',
              thumbnailImage: 'hapi',
              date: '12/04/2020',
              description: 'Analiza radiografías PA de torax para detectar pacientes con neumonía. El análisis se hace respetando la privacidad de los datos, ya que las radiografías no salen del navegador web.'
          }
        ]
    

        return (
            <Layout title='Demos | Modelizame' description=''>
                
                <section className="section margin-top-navbar" style={{minHeight: '80vh'}}>
                  <div className="container">
                      {/* <div className="content"> */}
                      <div className="columns is-multiline is-mobile">
 
                        {posts.map(( node ) => {
                          const title = node.title
                          return (
                            <article className="column is-6-desktop column is-6-tablet is-12-mobile" key={node.key}>
                              <Link to={node.url}>
                              <div className="card blog-entry">
                                <div>

                                
                                <div className="card-image">
                                  <figure className="image">
                                    <Img fluid={data[node.thumbnailImage].childImageSharp.fluid} alt={title} style={{height: '100%'}}/>
                                  </figure>
                                </div>
                                <div className="card-content">
                                  <p className="title is-3">
                                  {title}
                                  </p>
                                </div>
                                </div>
                                <div className="card-content">
                                  <div className="content">
                                    {node.date} - {node.description} 
                                  </div>
                                </div>
                              </div>
                              </Link>
                            </article>
                          )
                        })}

                      </div>

                      </div>
                  {/* </div> */}
                </section>

            </Layout>
        )
    
}

